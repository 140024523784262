import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import MintTokenPage from './MintTokenPage';
import MyTokensPage from './MyTokensPage';
import BurnTokenPage from './BurnTokenPage';
import TokenFactoryArtifact from './artifacts/contracts/TokenFactory.sol/TokenFactory.json';
import './App.css'; 
import TokenPriceTracker from './TokenPriceTracker';
import CopyButton from './components/CopyButton';
import BackgroundCanvas from './components/BackgroundCanvas';
import WalletIcon from './assets/wallet-icon.svg';
import PrestigeLogo from './images/prestigelogo.png';
const ethers = require("ethers");

const TokenFactory = () => {
    const [account, setAccount] = useState('');
    const [contract, setContract] = useState(null);
    const [tokenName, setTokenName] = useState('');
    const [tokenSymbol, setTokenSymbol] = useState('');
    const [initialSupply, setInitialSupply] = useState('');
    const [error, setError] = useState('');
    const [owner, setOwner] = useState('');
    const [newTokenAddress, setNewTokenAddress] = useState(''); 
    const [userTokenBalance, setUserTokenBalance] = useState(''); 
    const [contractBalance, setContractBalance] = useState('0'); 
	const [isLoading, setIsLoading] = useState(false); 
	const [agcBalance, setAgcBalance] = useState('');  
	const [projectDescription, setProjectDescription] = useState('');
    const [revokeMintingAuthority, setRevokeMintingAuthority] = useState(false);
    const [revokePausability, setRevokePausability] = useState(false);
    const [decimals, setDecimals] = useState(18); // Default value set to 18
    const [uploadedImage, setUploadedImage] = useState({ fileName: "", preview: "" });
    const [createTokenError, setCreateTokenError] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const baseFee = 1; // Base fee in AGC
    const revokeFee = 1; // Additional fee for each option in AGC


    const calculateTotalFees = () => {
        let total = baseFee;
        if (revokeMintingAuthority) total += revokeFee;
        if (revokePausability) total += revokeFee;
        return total;
    };

    useEffect(() => {
        // Screen size detection
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 768); // Update state for screen width <= 768px
        };
    
        checkScreenSize(); // Initial check
        window.addEventListener('resize', checkScreenSize); // Listen for window resize
    
        return () => {
            window.removeEventListener('resize', checkScreenSize); // Cleanup on unmount
        };
    }, []); // Only runs on mount/unmount
    
    useEffect(() => {
        // Chain change detection
        const handleChainChanged = (chainId) => {
            window.location.reload();
        };
    
        window.ethereum && window.ethereum.on('chainChanged', handleChainChanged);
    
        return () => {
            window.ethereum && window.ethereum.removeListener('chainChanged', handleChainChanged);
        };
    }, []); // Only runs on mount/unmount
    

    const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            console.log("Connecting to wallet...");
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

            if (accounts.length === 0) {
                throw new Error('No accounts found. Please make sure MetaMask is unlocked.');
            }

            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const account = await signer.getAddress();
            setAccount(account);

            
            const balance = await provider.getBalance(account);   
            const formattedBalance = ethers.formatUnits(balance, 18);  
            setAgcBalance(formattedBalance); 

            const tokenFactoryAddress = '0xD147473E4952E75878b967686feedc24137A084C'; 
            const tokenFactoryContract = new ethers.Contract(tokenFactoryAddress, TokenFactoryArtifact.abi, signer);
            
            setContract(tokenFactoryContract);

            const ownerAddress = await tokenFactoryContract.owner();
            setOwner(ownerAddress);

            console.log("Wallet connected:", account);
        } catch (error) {
            console.error("Error connecting to wallet:", error.message);
            setError("Failed to connect to wallet: " + error.message);
        }
    } else {
        alert("MetaMask not detected. Please install MetaMask.");
        console.error("MetaMask not detected.");
    }
};


const incrementDecimals = () => {
    setDecimals((prev) => Math.min(prev + 1, 18));
};

const decrementDecimals = () => {
    setDecimals((prev) => Math.max(prev - 1, 2));
};

const createToken = async () => {
    setCreateTokenError(''); // Reset error message initially

    if (!contract) {
        console.error("Please connect your wallet.");
        setCreateTokenError("Please connect your wallet.");
        return;
    }

    try {
        setIsLoading(true);

        const supply = ethers.parseUnits(initialSupply.toString(), decimals);
        const fee = ethers.parseUnits(calculateTotalFees().toString(), "ether");

        console.log("Token Name:", tokenName);
        console.log("Token Symbol:", tokenSymbol);
        console.log("Initial Supply (BigNumber):", supply.toString());
        console.log("Decimals:", decimals);
        console.log("Revoke Mint Authority:", revokeMintingAuthority);
        console.log("Revoke Pausability:", revokePausability);
        console.log("Total Fee in AGC:", fee.toString());

        const gasLimit = 6000000;

        const tx = await contract.createToken(
            tokenName,
            tokenSymbol,
            supply,
            decimals,
            revokeMintingAuthority,
            revokePausability,
            { 
                value: fee,
                gasLimit: gasLimit // Passing BigInt directly
            }
        );

        const receipt = await tx.wait();
        console.log("Token creation successful, transaction receipt:", receipt);

        const tokenCreatedEvent = receipt.logs.map(log => {
            try {
                return contract.interface.parseLog(log);
            } catch (e) {
                return null;
            }
        }).find(log => log && log.name === 'TokenCreated');

        if (tokenCreatedEvent) {
            const tokenAddress = tokenCreatedEvent.args.tokenAddress;
            console.log("New Token Address:", tokenAddress);
            setNewTokenAddress(tokenAddress);

            await checkTokenBalance(tokenAddress, account);
        }
        await refreshBalance();
    } catch (error) {
        console.error("Error creating token:", error);

        if (error.message.includes("Insufficient fee")) {
            setCreateTokenError("Insufficient fee provided. Please ensure you are covering all costs.");
        } else if (error.message.includes("Initial supply exceeds maximum allowed")) {
            setCreateTokenError("Initial supply exceeds the maximum allowed limit.");
        } else if (error.code === 'CALL_EXCEPTION') {
            setCreateTokenError("Transaction reverted. Please check the parameters and try again.");
        } else {
            setCreateTokenError("Error creating token. Please try again.");
        }
    } finally {
        setIsLoading(false);
    }
};

  const refreshBalance = useCallback(async () => {
        if (account) {
            try {
                const provider = new ethers.BrowserProvider(window.ethereum);
                const balance = await provider.getBalance(account);
                const formattedBalance = ethers.formatUnits(balance, 18);
                console.log("Updated AGC balance:", formattedBalance);
                setAgcBalance(formattedBalance);
            } catch (error) {
                console.error("Failed to refresh AGC balance:", error);
            }
        }
    }, [account]);

            useEffect(() => {
                if (account) {
                    refreshBalance();
                }
            }, [account, refreshBalance]);
    
            useEffect(() => {
                if (newTokenAddress) {
                    refreshBalance();
                }
            }, [newTokenAddress, refreshBalance]);
    


    const checkTokenBalance = async (tokenAddress, account) => {
        try {
            const ERC20_ABI = [
                "function balanceOf(address account) view returns (uint256)",
            ];

            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, signer);
            
            const balance = await tokenContract.balanceOf(account);
            const formattedBalance = ethers.formatUnits(balance, decimals); 
            console.log(`Token balance for account ${account}:`, formattedBalance);
            setUserTokenBalance(formattedBalance); 
        } catch (error) {
            console.error("Error fetching token balance:", error);
        }
    };

    const checkContractBalance = async () => {
    try {
        const contractAddress = '0xD147473E4952E75878b967686feedc24137A084C'; 
        const provider = new ethers.BrowserProvider(window.ethereum);
        
        const balance = await provider.getBalance(contractAddress);
        const formattedBalance = ethers.formatUnits(balance, 18); 
        
        console.log(`Contract balance: ${formattedBalance} AGC`);
        
        
        setContractBalance(formattedBalance);

    } catch (error) {
        console.error("Error checking contract balance:", error);
        setError("Error checking contract balance: " + error.message);
    }
};






    const withdrawFees = async () => {
    try {
        const tokenFactoryAddress = '0xD147473E4952E75878b967686feedc24137A084C'; 
        const provider = new ethers.BrowserProvider(window.ethereum);

        const contractBalance = await provider.getBalance(tokenFactoryAddress);
        console.log(`Contract balance before withdrawal: ${ethers.formatUnits(contractBalance, 18)} AGC`);

        if (contractBalance > 0) {
            const tx = await contract.withdraw();  
            await tx.wait();
            console.log("Fees withdrawn successfully");
        } else {
            console.error("No AGC to withdraw");
            setError("No AGC available to withdraw.");
        }

    } catch (error) {
        console.error("Error withdrawing fees:", error);
        setError("Failed to withdraw fees: " + error.message);
    }
};

    const addTokenToMetaMask = async () => {
    if (!newTokenAddress || !tokenSymbol || !tokenName) {
        console.error("Token details not available yet.");
        setError("Token details not available yet.");
        return;
    }

    try {
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: newTokenAddress, 
                    symbol: tokenSymbol,      
                    decimals: decimals,             
                    image: '',                
                },
            },
        });

        if (wasAdded) {
            console.log("Token added to MetaMask");
        } else {
            console.error("Token not added to MetaMask");
        }
    } catch (error) {
        console.error("Error adding token to MetaMask:", error);
        setError("Error adding token to MetaMask: " + error.message);
    }
};



if (isMobile) {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            background: "linear-gradient(135deg, #1b1b1f, #101018)",
            color: "#ffffff",
            fontSize: "1.8rem",
            textAlign: "center",
            fontFamily: "'Nunito', sans-serif",
            padding: "20px",
            flexDirection: "column",
            gap: "20px", // Add spacing between elements
        }}>
            <img 
                src={PrestigeLogo} 
                alt="Prestige Logo" 
                style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "contain",
                    marginBottom: "20px",
                }} 
            />
            <div style={{
                background: "linear-gradient(135deg, #ff7eb3, #32d5f2)",
                padding: "20px 40px",
                borderRadius: "16px",
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.6)",
            }}>
                <p style={{
                    margin: 0,
                    lineHeight: "1.5",
                    textTransform: "uppercase",
                    fontWeight: "700",
                }}>
                    Please Use a Desktop or Laptop to Access this Website
                </p>
            </div>
            <p style={{
                marginTop: "20px",
                fontSize: "1.2rem",
                color: "#cccccc",
                maxWidth: "600px",
                lineHeight: "1.6",
            }}>
                For the best experience, we recommend using a computer. This site is not optimised for mobile devices.
            </p>
        </div>
    );
}



return (
    <Router>
        {/* Background Canvas for animated particles */}
        <BackgroundCanvas />
        <nav className="navbar">
                <NavLink to="/" className="navbar-link" activeClassName="active-link">
                    Create a Token
                </NavLink>
                <NavLink to="/mint" className="navbar-link" activeClassName="active-link">
                    Mint Tokens
                </NavLink>
                <NavLink to="/burn" className="navbar-link" activeClassName="active-link">
                    Burn Tokens
                </NavLink>
                <NavLink to="/my-tokens" className="navbar-link" activeClassName="active-link">
                    My Tokens
                </NavLink>
        </nav>
        
        <Routes>
            <Route 
                path="/" 
                element={
                    <div className="container">
                        {/* Live Token Price Tracker at the top center of the container */}
                        <div className="price-section top-center">
                            <TokenPriceTracker />
                        </div>

                        <h1>Argochain Token Launcher</h1>
                        <p className="small-text">Made by PrestigeNode</p>
                        <div className="wallet-info">
            {!account ? (
                <button className="connect-wallet1" onClick={connectWallet}>
                    <img src={WalletIcon} alt="Wallet Icon" className="wallet-icon" />
                    Connect Wallet
                </button>
            ) : (
                <div className="connected-wallet">
                    <img src={WalletIcon} alt="Wallet Icon" className="wallet-icon" />
                    <span className="wallet-address" title={account}>
                        {`${account.slice(0, 6)}...${account.slice(-4)}`}
                    </span>
                    <span className="wallet-balance">
                        {agcBalance ? `${parseFloat(agcBalance).toFixed(2)} AGC` : "Loading..."}
                    </span>
                </div>
            )}
        </div>

                        {!newTokenAddress ? (
                            <div className="token-form">
                                {/* Header Section */}
                                <div className="form-header-container">
                                    <h2 className="form-header">Create a New Token</h2>
                                    <span className="fee-text">(Fee: {baseFee} AGC)</span>
                                </div>

                                <div className="form-row">
                                    <div className="form-group">
                                        <label className="form-label">Token Name</label>
                                        <input
                                            type="text"
                                            placeholder="e.g. Argocoin"
                                            value={tokenName}
                                            onChange={(e) => setTokenName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Token Symbol</label>
                                        <input
                                            type="text"
                                            placeholder="e.g. AGC"
                                            value={tokenSymbol}
                                            onChange={(e) => {
                                                const value = e.target.value.toUpperCase().replace(/[^A-Z]/g, '');
                                                setTokenSymbol(value);
                                            }}
                                            maxLength={4}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group token-decimals-container">
                                        <label className="form-label">Token Decimals</label>
                                        <div className="token-decimals-input">
                                            <button
                                                type="button"
                                                className="decrement-button"
                                                onClick={decrementDecimals}
                                            >
                                                -
                                            </button>
                                            <input
                                                type="text"
                                                value={decimals}
                                                readOnly
                                                className="decimals-display"
                                            />
                                            <button
                                                type="button"
                                                className="increment-button"
                                                onClick={incrementDecimals}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Token Supply</label>
                                        <input
                                            type="text"
                                            placeholder="e.g. 1000000000"
                                            value={initialSupply}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const validInteger = /^[0-9]*$/;
                                                const MAX_SUPPLY = 1e12;

                                                if (value === '' || validInteger.test(value)) {
                                                    if (Number(value) > MAX_SUPPLY) {
                                                        setError(`Token supply cannot exceed ${MAX_SUPPLY.toLocaleString()}`);
                                                    } else {
                                                        setError('');
                                                    }
                                                    setInitialSupply(value);
                                                }
                                            }}
                                        />
                                        {error && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
                                    </div>
                                </div>

                                <div className="form-row full-width">
                                    <div className="form-group description-box" style={{ width: '100%' }}>
                                        <label className="form-label">Description</label>
                                        <textarea
                                            placeholder="Project Description (optional)"
                                            value={projectDescription}
                                            onChange={(e) => setProjectDescription(e.target.value)}
                                            rows="4"
                                        />
                                    </div>
                                    <div className="form-group logo-url-box">
                                        <label className="form-label">Logo Upload</label>
                                        <div 
                                            className="upload-button-as-input" 
                                            onClick={() => document.getElementById('file-upload').click()}
                                        >
                                            {uploadedImage && uploadedImage instanceof File ? uploadedImage.name : "UPLOAD IMAGE"}
                                        </div>
                                        <input
                                            id="file-upload"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) {
                                                    setUploadedImage(file);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="checkbox-container">
                                    <label className="toggle-group">
                                        <input
                                            type="checkbox"
                                            className="toggle-input"
                                            onChange={(e) => setRevokeMintingAuthority(e.target.checked)}
                                        />
                                        <span className="toggle-slider"></span>
                                        Revoke Mint Authority
                                        <span className="info-icon">
                                            i
                                            <span className="tooltip-text">Disables the ability to mint more tokens for this contract. Cost: 5 AGC.</span>
                                        </span>
                                    </label>        

                                    <label className="toggle-group">
                                        <input
                                            type="checkbox"
                                            className="toggle-input"
                                            onChange={(e) => setRevokePausability(e.target.checked)}
                                        />
                                        <span className="toggle-slider"></span>
                                        Revoke Pausable
                                        <span className="info-icon">
                                            i
                                            <span className="tooltip-text">Disables the ability to pause transfers for this contract. Cost: 5 AGC.</span>
                                        </span>
                                    </label>
                                </div>

                                <button onClick={createToken} className='create-token-button'>Create Token</button>
                                {createTokenError && <p style={{ color: 'red', marginTop: '5px' }}>{createTokenError}</p>}
                                <p className="total-fees">Total Fees: {calculateTotalFees()} AGC</p>

                                {/* Preview Section */}
                                {(tokenName || tokenSymbol || initialSupply || projectDescription) && (
                                    <div className="token-preview">
                                        <h3>Token Preview</h3>
                                        <div className="preview-logo">
                                            {uploadedImage && uploadedImage instanceof File ? (
                                                <img
                                                    src={URL.createObjectURL(uploadedImage)}
                                                    alt="Token Logo Preview"
                                                    className="circle-logo"
                                                />
                                            ) : (
                                                <span className="placeholder-logo">No Logo</span>
                                            )}
                                        </div>
                                        {tokenName && (
                                            <p>
                                                <strong className="token-attribute">Name:</strong>{" "}
                                                <span className="attribute-text">{tokenName}</span>
                                            </p>
                                        )}
                                        {tokenSymbol && (
                                            <p>
                                                <strong className="token-attribute">Symbol:</strong>{" "}
                                                <span className="attribute-text">{tokenSymbol}</span>
                                            </p>
                                        )}
                                        {initialSupply && (
                                            <p>
                                                <strong className="token-attribute">Supply:</strong>{" "}
                                                <span className="attribute-text">{Number(initialSupply).toLocaleString()}</span>
                                            </p>
                                        )}
                                        <p>
                                            <strong className="token-attribute">Decimals:</strong>{" "}
                                            <span className="attribute-text">{decimals}</span>
                                        </p>
                                        {projectDescription && (
                                            <p className="preview-description">
                                                <strong className="token-attribute">Description:</strong>{" "}
                                                <span className="attribute-text">{projectDescription}</span>
                                            </p>
                                        )}
                                        <p>
                                            <strong className="token-attribute">Revoke Mint Authority:</strong>{" "}
                                            <span
                                                style={{
                                                    color: revokeMintingAuthority ? "green" : "red",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {revokeMintingAuthority ? "Yes" : "No"}
                                            </span>
                                        </p>
                                        <p>
                                            <strong className="token-attribute">Revoke Pausable:</strong>{" "}
                                            <span
                                                style={{
                                                    color: revokePausability ? "green" : "red",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {revokePausability ? "Yes" : "No"}
                                            </span>
                                        </p>
                                    </div>
                                )}

                            </div>
                        ) : (
                            <div id="token-details" className="token-created">
                                <h3>New Token Created Successfully!</h3>
                                {/* Display Token Logo */}
                                <div className="preview-logo">
                                    {uploadedImage && uploadedImage instanceof File ? (
                                        <img src={URL.createObjectURL(uploadedImage)} alt="Token Logo" className="circle-logo" />
                                    ) : (
                                        <span className="placeholder-logo">No Logo</span>
                                    )}
                                </div>
                                {/* New Token Details */}
                                <div className="attribute-wrapper">
                                    <span className="token-attribute">Token Name:</span>
                                    <span className="attribute-text"> {tokenName}</span>
                                </div>
                                <div className="attribute-wrapper">
                                    <span className="token-attribute">Token Symbol:</span>
                                    <span className="attribute-text"> {tokenSymbol}</span>
                                </div>
                                <div className="attribute-wrapper">
                                    <span className="token-attribute">Token Address:</span>
                                    <span className="attribute-text"> {newTokenAddress}</span>
                                    {newTokenAddress && <CopyButton textToCopy={newTokenAddress} label="Token Address" />}
                                </div>
                                <div className="attribute-wrapper">
                                    <span className="token-attribute">Token Supply: </span>
                                    <span className="attribute-text"> 
                                    {Number(userTokenBalance) % 1 === 0 ? Number(userTokenBalance) : Number(userTokenBalance).toFixed(2).replace(/\.0+$/, '')}
                                    </span>
                                </div>
                                <div className="attribute-wrapper">
                                    <span className="token-attribute">Decimals:</span>
                                    <span className="attribute-text"> {decimals}</span>
                                </div>
                                {projectDescription && (
                                    <div className="attribute-wrapper">
                                        <span className="token-attribute">Description:</span>
                                        <span className="attribute-text"> {projectDescription}</span>
                                    </div>
                                )}
                                <div className="attribute-wrapper" style={{ color: revokeMintingAuthority ? 'red' : 'red' }}>
                                    <span className="token-attribute">Revoke Mint Authority:</span>
                                    <span className="attribute-text"> {revokeMintingAuthority ? 'Yes' : 'No'}</span>
                                </div>
                                <div className="attribute-wrapper" style={{ color: revokePausability ? 'red' : 'red' }}>
                                    <span className="token-attribute">Revoke Pausable:</span>
                                    <span className="attribute-text"> {revokePausability ? 'Yes' : 'No'}</span>
                                </div>
                                <div className="button-wrapper">
                                    <button onClick={addTokenToMetaMask} className="add-to-metamask-btn">Add Token to MetaMask</button>
                                </div>
                            </div>
                        )}

                        {isLoading && (
                            <div className="loading-overlay">
                                <div className="loading-spinner"></div>
                                <p>Deploying your token... Please wait.</p>
                            </div>
                        )}

                        {account && contract && account.toLowerCase() === owner.toLowerCase() && (
                            <div className="owner-actions">
                                <h2>Owner Actions</h2>
                                <button onClick={withdrawFees} className='withdraw-fees-button'>Withdraw Fees</button>
                                <h3>Contract Balance: {contractBalance} AGC</h3>
                                <button onClick={checkContractBalance} className='check-contract-balance-button'>Check Contract Balance</button>
                            </div>
                        )}
                    </div>
                }
            />
            <Route 
                path="/mint" 
                element={<MintTokenPage account={account} connectWallet={connectWallet} agcBalance={agcBalance} />} 
            />
            <Route 
                path="/my-tokens" 
                element={<MyTokensPage account={account} connectWallet={connectWallet} agcBalance={agcBalance} />} 
            />
            <Route 
                path="/burn" 
                element={<BurnTokenPage account={account} connectWallet={connectWallet} agcBalance={agcBalance} />} 
            />
        </Routes>
    </Router>
)};





export default TokenFactory;

