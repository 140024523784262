import React from 'react';
import './AnalyticsModal.css'; // Styling for the modal

const AnalyticsModal = ({ isOpen, onClose, analytics }) => {
  if (!isOpen) return null;

  return (
    <div className="analytics-modal-overlay">
      <div className="analytics-modal">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h2 className="modal-header">Token Analytics</h2>
        {analytics ? (
          <div className="analytics-content">
            <p><strong>Total Supply:</strong> {analytics.totalSupply}</p>
            <p><strong>Transaction Volume:</strong> {analytics.transactionVolume}</p>
            <p><strong>Active Accounts:</strong> {analytics.activeAccounts}</p>
            <p><strong>Mint Count:</strong> {analytics.mintCount}</p>
            <p><strong>Burn Count:</strong> {analytics.burnCount}</p>
            <div>
              <h3>Recent Transfers:</h3>
              <ul>
                {analytics.recentTransfers.map((t, index) => (
                  <li key={index}>
                    From: {t.from}, To: {t.to}, Amount: {t.amount}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <p>Loading analytics...</p>
        )}
      </div>
    </div>
  );
};

export default AnalyticsModal;
