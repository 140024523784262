import React, { useState } from "react";
import "./TransferModal.css";
const ethers = require("ethers");

const TransferModal = ({ tokens, tokenAddress, onTransfer, onClose }) => {
    const [selectedToken, setSelectedToken] = useState(tokenAddress); // Default to the token that triggered the modal
    const [recipient, setRecipient] = useState("");
    const [amount, setAmount] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false); // Track loading state

    const handleTransfer = async () => {
        if (!selectedToken || !recipient || !amount || isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
            setError("Please fill out all fields with valid inputs.");
            return;
        }
    
        setError(""); // Clear previous errors
        setSuccess(false); // Clear previous success messages
        setLoading(true); // Start loading state
    
        try {
            // Define ABI for token contract interaction
            const ERC20_ABI = [
                "function transfer(address to, uint256 amount) external returns (bool)",
                "function decimals() view returns (uint8)"
            ];
    
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const tokenContract = new ethers.Contract(selectedToken, ERC20_ABI, signer);
    
            // Fetch token decimals dynamically
            const decimals = await tokenContract.decimals();
            const parsedDecimals = parseInt(decimals, 10);
    
            if (isNaN(parsedDecimals)) {
                throw new Error("Invalid token decimals.");
            }
    
            // Convert amount to smallest unit using token's decimals
            const parsedAmount = ethers.parseUnits(amount, parsedDecimals);
    
            // Initiate the transfer
            const tx = await tokenContract.transfer(recipient, parsedAmount);
            await tx.wait(); // Wait for the transaction to be mined
    
            setSuccess(true);
            setRecipient("");
            setAmount("");
        } catch (error) {
            console.error("Transfer Error:", error);
    
            // Handle specific error scenarios
            if (error.code === 4001 || (error.message && error.message.includes("user denied transaction signature"))) {
                // Suppress verbose cancellation message
                setError("Transaction cancelled by the user.");
            } else if (error.message && error.message.includes("revert")) {
                setError("Transaction reverted. Please ensure all details are correct.");
            } else {
                setError("Transfer failed. Please check the details and try again.");
            }
        } finally {
            setLoading(false); // Always stop loading
        }
    };
    

    return (
        <div className="modal-backdrop">
            <div className="modal-container">
                <h2 className="modal-header">Transfer Token</h2>
                {loading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner"></div>
                        <p>Processing transfer...</p>
                    </div>
                )}
                <div className="modal-body">
                    <div className="modal-form-group">
                        <label htmlFor="token-select" className="modal-label">
                            Select Token
                        </label>
                        <select
                            id="token-select"
                            className="modal-input"
                            value={selectedToken}
                            onChange={(e) => setSelectedToken(e.target.value)}
                        >
                            {tokens.map((token) => (
                                <option key={token.address} value={token.address}>
                                    {token.name} ({token.symbol})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="modal-form-group">
                        <label htmlFor="recipient" className="modal-label">
                            Recipient Address
                        </label>
                        <input
                            type="text"
                            id="recipient"
                            className="modal-input"
                            value={recipient}
                            onChange={(e) => setRecipient(e.target.value)}
                            placeholder="Enter recipient address"
                        />
                    </div>
                    <div className="modal-form-group">
                        <label htmlFor="amount" className="modal-label">
                            Amount
                        </label>
                        <input
                            type="text" // Allows input control
                            id="amount"
                            className="modal-input"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            onKeyDown={(e) => {
                                // Allow only numbers, one decimal point, and control keys
                                if (
                                    !(
                                        (e.key >= "0" && e.key <= "9") || // Numbers
                                        e.key === "." || // Decimal point
                                        e.key === "Backspace" || // Allow Backspace
                                        e.key === "ArrowLeft" || // Allow Left Arrow
                                        e.key === "ArrowRight" || // Allow Right Arrow
                                        e.key === "Delete" // Allow Delete
                                    )
                                ) {
                                    e.preventDefault();
                                }

                                // Prevent multiple decimal points
                                if (e.key === "." && amount.includes(".")) {
                                    e.preventDefault();
                                }
                            }}
                            placeholder="Enter amount to transfer"
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">Transfer successful!</p>}
                </div>
                <div className="modal-footer">
                    <button className="modal-button secondary" onClick={onClose} disabled={loading}>
                        Close
                    </button>
                    <button
                        className="modal-button primary"
                        onClick={handleTransfer}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Transfer"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TransferModal;
