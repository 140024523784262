import React, { useState, useEffect } from 'react';
import TokenPriceTracker from './TokenPriceTracker';
import WalletIcon from './assets/wallet-icon.svg';
const ethers = require("ethers");

const MintTokenPage = ({ account, connectWallet, agcBalance }) => {
    const [userTokens, setUserTokens] = useState([]);
    const [selectedToken, setSelectedToken] = useState('');
    const [mintAmount, setMintAmount] = useState('');
    const [mintTokenError, setMintTokenError] = useState('');
    const [loadingTokens, setLoadingTokens] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const TokenFactoryArtifact = require('./artifacts/contracts/TokenFactory.sol/TokenFactory.json');

    // Fetch User Tokens
    useEffect(() => {
        const fetchUserTokens = async () => {
            if (!account) return;

            try {
                setLoadingTokens(true);
                const tokenFactoryAddress = '0xD147473E4952E75878b967686feedc24137A084C'; // Replace with your TokenFactory address
                const provider = new ethers.BrowserProvider(window.ethereum);
                const signer = await provider.getSigner();
                const tokenFactoryContract = new ethers.Contract(tokenFactoryAddress, TokenFactoryArtifact.abi, signer);

                const tokens = await tokenFactoryContract.getUserTokens(account);

                const enrichedTokens = await Promise.all(
                    tokens.map(async (address) => {
                        const tokenContract = new ethers.Contract(
                            address,
                            [
                                "function name() view returns (string)",
                                "function symbol() view returns (string)"
                            ],
                            provider
                        );
                        const name = await tokenContract.name();
                        const symbol = await tokenContract.symbol();

                        return { address, name, symbol };
                    })
                );

                setUserTokens(enrichedTokens);

                // Set the default selected token if tokens exist
                if (enrichedTokens.length > 0) {
                    setSelectedToken(enrichedTokens[0].address);
                }
            } catch (error) {
                console.error("Error fetching user tokens:", error);
            } finally {
                setLoadingTokens(false);
            }
        };

        fetchUserTokens();
    }, [account]);

    const checkMintAuthority = async (tokenContract) => {
        const MINTER_ROLE = ethers.id("MINTER_ROLE"); // Adjust this if your contract uses a different role identifier
        try {
            const hasMintAuthority = await tokenContract.hasRole(MINTER_ROLE, account);
            return hasMintAuthority;
        } catch (error) {
            console.error("Error checking mint authority:", error);
            return false; // Assume minting is revoked if there's an error
        }
    };

    const mintTokens = async () => {
        setMintTokenError(''); // Reset error message initially
        setIsLoading(true); // Show loading spinner
    
        if (!selectedToken) {
            setMintTokenError("Please select a token to mint.");
            setIsLoading(false);
            return;
        }
    
        if (!mintAmount || isNaN(mintAmount) || parseFloat(mintAmount) <= 0) {
            setMintTokenError("Please enter a valid mint amount.");
            setIsLoading(false);
            return;
        }
    
        try {
            const ERC20_ABI = [
                "function mint(address to, uint256 amount) external",
                "function decimals() view returns (uint8)"
            ];
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const tokenContract = new ethers.Contract(selectedToken, ERC20_ABI, signer);
    
            const actualDecimals = await tokenContract.decimals();
            const amount = ethers.parseUnits(mintAmount.toString(), actualDecimals);
    
            // Attempt to mint tokens
            const tx = await tokenContract.mint(account, amount);
            await tx.wait();
    
            setMintTokenError(
                <div className="transaction-message">
                    <p>Minted {mintAmount} tokens to address: {account}</p>
                    <p>
                        Transaction ID:{" "}
                        <a
                            href={`https://scanner.argoscan.net/tx/${tx.hash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {tx.hash}
                        </a>
                    </p>
                </div>
            );
    
            setMintAmount(''); // Reset the mint amount input
        } catch (error) {
            console.error("Error minting tokens:", error);
    
            // Check for specific error related to revoked mint authority
            if (error.reason && error.reason.includes("Minting has been revoked")) {
                setMintTokenError("Minting authority has been revoked for this token.");
            } else if (error.message.includes("revert")) {
                setMintTokenError("Transaction failed. Please check if minting authority is revoked.");
            } else {
                setMintTokenError("Failed to mint tokens. Please try again.");
            }
        } finally {
            setIsLoading(false); // Hide loading spinner
        }
    };    

    return (
        <div className="mint-container">
            <div className="price-section top-center">
                <TokenPriceTracker />
            </div>

            <h1 className="main-header">Mint Tokens</h1>
            <p className="small-text">Made by PrestigeNode</p>
            <div className="wallet-info">
                {!account ? (
                    <button className="connect-wallet1" onClick={connectWallet}>
                        <img src={WalletIcon} alt="Wallet Icon" className="wallet-icon" />
                        Connect Wallet
                    </button>
                ) : (
                    <div className="connected-wallet">
                        <img src={WalletIcon} alt="Wallet Icon" className="wallet-icon" />
                        <span className="wallet-address" title={account}>
                            {`${account.slice(0, 6)}...${account.slice(-4)}`}
                        </span>
                        <span className="wallet-balance">
                            {agcBalance ? `${parseFloat(agcBalance).toFixed(2)} AGC` : "Loading..."}
                        </span>
                    </div>
                )}
            </div>

            <div className="token-form">
                <h2 className="form-header">Mint More of Your Token</h2>
                <div className="form-group">
                    <label className="form-label">Select Token</label>
                    <select
                        value={selectedToken}
                        onChange={(e) => setSelectedToken(e.target.value)}
                        className="token-select"
                    >
                        {loadingTokens ? (
                            <option>Loading tokens...</option>
                        ) : userTokens.length === 0 ? (
                            <option value="">No tokens available</option>
                        ) : (
                            userTokens.map((token, index) => (
                                <option key={index} value={token.address}>
                                    {token.name} ({token.symbol}) - {`${token.address.slice(0, 6)}...${token.address.slice(-4)}`}
                                </option>
                            ))
                        )}
                    </select>
                </div>
                <div className="form-group">
                    <label className="form-label">Amount to Mint</label>
                    <input
                        type="text"
                        inputMode="numeric"
                        pattern="^\d*$"
                        placeholder="Enter amount to mint"
                        value={mintAmount}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                setMintAmount(value);
                            }
                        }}
                    />
                </div>
                <button onClick={mintTokens} className="mint-button">Mint Tokens</button>
                {mintTokenError && <p style={{ color: 'red', marginTop: '5px' }}>{mintTokenError}</p>}
            </div>

            {isLoading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                    <p>Minting tokens... Please wait.</p>
                </div>
            )}
        </div>
    );
};

export default MintTokenPage;
