import React, { useEffect } from "react";

const BackgroundCanvas = () => {
    useEffect(() => {
        const canvas = document.getElementById("background-canvas");
        const ctx = canvas.getContext("2d");

        const particles = [];
        let particleCount = 150; // Initial number of particles
        const maxDistance = 150; // Max distance to connect lines
        const cursor = { x: null, y: null }; // Track cursor position

        // Gradient colors
        let gradient;

        const initializeGradient = () => {
            gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
            gradient.addColorStop(0, "#00bfff"); // Light blue
            gradient.addColorStop(0.6, "#4b0082"); // Purple
            gradient.addColorStop(1, "#ff69b4"); // Pink
        };

        class Particle {
            constructor() {
                this.x = Math.random() * canvas.width;
                this.y = Math.random() * canvas.height;
                this.velocityX = (Math.random() - 0.5) * 0.5; // Slower speed
                this.velocityY = (Math.random() - 0.5) * 0.5; // Slower speed
                this.size = Math.random() * 2 + 1;
                this.opacity = Math.random() * 0.5 + 0.5; // Semi-transparent
            }

            draw() {
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
                ctx.fillStyle = gradient; // Gradient fill for particles
                ctx.globalAlpha = this.opacity; // Apply opacity
                ctx.fill();
                ctx.globalAlpha = 1; // Reset opacity for other elements
            }

            update() {
                this.x += this.velocityX;
                this.y += this.velocityY;

                // Bounce off edges
                if (this.x < 0 || this.x > canvas.width) this.velocityX *= -1;
                if (this.y < 0 || this.y > canvas.height) this.velocityY *= -1;
            }
        }

        const initializeParticles = () => {
            particles.length = 0; // Clear existing particles
            for (let i = 0; i < particleCount; i++) {
                particles.push(new Particle());
            }
        };

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            // Dynamically adjust particle count based on screen size
            particleCount = Math.max(50, Math.floor((canvas.width * canvas.height) / 10000));
            initializeGradient();
            initializeParticles();
        };

        const animate = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            particles.forEach((particle, index) => {
                particle.draw();
                particle.update();

                // Draw connections
                for (let j = index + 1; j < particles.length; j++) {
                    const dx = particle.x - particles[j].x;
                    const dy = particle.y - particles[j].y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < maxDistance) {
                        ctx.beginPath();
                        ctx.moveTo(particle.x, particle.y);
                        ctx.lineTo(particles[j].x, particles[j].y);
                        ctx.strokeStyle = `rgba(50, 213, 242, ${1 - distance / maxDistance})`;
                        ctx.lineWidth = 0.5;
                        ctx.stroke();
                    }
                }

                // Cursor interaction
                if (cursor.x && cursor.y) {
                    const dx = particle.x - cursor.x;
                    const dy = particle.y - cursor.y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < 100) {
                        const angle = Math.atan2(dy, dx);
                        particle.x += Math.cos(angle) * 2; // Repel particles
                        particle.y += Math.sin(angle) * 2;
                    }
                }
            });

            requestAnimationFrame(animate);
        };

        // Track mouse movement
        const updateCursor = (e) => {
            cursor.x = e.clientX;
            cursor.y = e.clientY;
        };

        const resetCursor = () => {
            cursor.x = null;
            cursor.y = null;
        };

        // Add event listeners
        window.addEventListener("mousemove", updateCursor);
        window.addEventListener("mouseout", resetCursor);
        window.addEventListener("resize", resizeCanvas);

        // Initialize canvas and particles
        resizeCanvas();
        animate();

        return () => {
            window.removeEventListener("mousemove", updateCursor);
            window.removeEventListener("mouseout", resetCursor);
            window.removeEventListener("resize", resizeCanvas);
        };
    }, []);

    return <canvas id="background-canvas" style={{ position: "fixed", top: 0, left: 0, zIndex: -1 }} />;
};

export default BackgroundCanvas;
