import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import TokenPriceTracker from './TokenPriceTracker';
import TransferModal from './TransferModal';
import AnalyticsModal from './AnalyticsModal';
import TokenFactoryArtifact from './artifacts/contracts/TokenFactory.sol/TokenFactory.json';
import WalletIcon from './assets/wallet-icon.svg';
import { Link, useNavigate } from 'react-router-dom';

const ERC20_ABI = [
    "function name() view returns (string)",
    "function symbol() view returns (string)",
    "function totalSupply() view returns (uint256)",
    "function balanceOf(address account) view returns (uint256)",
    "function decimals() view returns (uint8)",
    "function mintingRevoked() view returns (bool)",
    "function pausabilityRevoked() view returns (bool)"
];

const MyTokensPage = ({ account, connectWallet }) => {
    const [agcBalance, setAgcBalance] = useState('');
    const [tokens, setTokens] = useState([]);
    const [tokenDetails, setTokenDetails] = useState([]);
    const [contract, setContract] = useState(null);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [sortOption, setSortOption] = useState('name');
    const [showRevokedMint, setShowRevokedMint] = useState(false);
    const [showRevokedPausable, setShowRevokedPausable] = useState(false);
    const [filterByDecimals, setFilterByDecimals] = useState(null);
    const [minBalance, setMinBalance] = useState('');
    const [maxBalance, setMaxBalance] = useState('');
    const [loadingTokens, setLoadingTokens] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentToken, setCurrentToken] = useState(null);
    const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
    const [currentAnalytics, setCurrentAnalytics] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!account) return;

        const initializeContract = async () => {
            const tokenFactoryAddress = '0xD147473E4952E75878b967686feedc24137A084C'; // Replace with your TokenFactory address
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const tokenFactoryContract = new ethers.Contract(tokenFactoryAddress, TokenFactoryArtifact.abi, signer);

            setContract(tokenFactoryContract);
        };

        initializeContract();
    }, [account]);

    const fetchUserTokens = useCallback(async () => {
        if (!contract || !account) return;

        try {
            const userTokens = await contract.getUserTokens(account);
            setTokens(userTokens);
        } catch (error) {
            console.error("Error fetching user tokens:", error);
        }
    }, [contract, account]);

    const fetchTokenDetails = useCallback(async (tokenAddress) => {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);

        try {
            const [name, symbol, totalSupply, balance, decimals, mintingRevoked, pausabilityRevoked] = await Promise.all([
                contract.name(),
                contract.symbol(),
                contract.totalSupply(),
                contract.balanceOf(account),
                contract.decimals(),
                contract.mintingRevoked(),
                contract.pausabilityRevoked(),
            ]);

            const formattedTotalSupply = ethers.formatUnits(totalSupply, decimals).replace(/\.0+$/, '');
            const formattedBalance = ethers.formatUnits(balance, decimals).replace(/\.0+$/, '');

            return {
                address: tokenAddress,
                name,
                symbol,
                totalSupply: formattedTotalSupply,
                balance: formattedBalance,
                decimals: decimals.toString(),
                mintingRevoked,
                pausabilityRevoked,
            };
        } catch (error) {
            console.error(`Error fetching details for token ${tokenAddress}:`, error);
            return null;
        }
    }, [account]);

    useEffect(() => {
        const loadTokenDetails = async () => {
            setLoadingTokens(true); // Show loading message
            if (tokens.length === 0) {
                setLoadingTokens(false);
                return;
            }

            const detailsPromises = tokens.map(fetchTokenDetails);
            const details = await Promise.all(detailsPromises);
            setTokenDetails(details.filter(Boolean));
            setLoadingTokens(false); // Hide loading message once done
        };

        loadTokenDetails();
    }, [tokens, fetchTokenDetails]);

    useEffect(() => {
        if (contract) fetchUserTokens();
    }, [contract, fetchUserTokens]);

    const sortedAndFilteredTokens = useMemo(() => {
        let filteredTokens = tokenDetails;
    
        // Apply existing filters
        if (showRevokedMint) {
            filteredTokens = filteredTokens.filter((token) => token.mintingRevoked);
        }
        if (showRevokedPausable) {
            filteredTokens = filteredTokens.filter((token) => token.pausabilityRevoked);
        }
    
        // Apply decimals filter
        if (filterByDecimals && filterByDecimals !== "all") {
            filteredTokens = filteredTokens.filter(
                (token) => Number(token.decimals) === Number(filterByDecimals) // Match numeric values
            );
        }
    
        // Apply balance range filters
        if (minBalance || maxBalance) {
            filteredTokens = filteredTokens.filter((token) => {
                const balance = parseFloat(token.balance);
                const min = parseFloat(minBalance) || 0;
                const max = parseFloat(maxBalance) || Infinity;
                return balance >= min && balance <= max;
            });
        }
    
        // Sort tokens
        return filteredTokens.sort((a, b) => {
            if (sortOption === "name") {
                return a.name.localeCompare(b.name);
            } else if (sortOption === "balance") {
                return parseFloat(b.balance) - parseFloat(a.balance); // Descending balance
            } else if (sortOption === "supply") {
                return parseFloat(b.totalSupply) - parseFloat(a.totalSupply); // Descending supply
            }
            return 0; // Default no sorting
        });
    }, [
        tokenDetails,
        sortOption,
        showRevokedMint,
        showRevokedPausable,
        filterByDecimals,
        minBalance,
        maxBalance,
    ]);
    
    const fetchAgcBalance = useCallback(async () => {
        if (!account) return;
    
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const balance = await provider.getBalance(account);
            const formattedBalance = ethers.formatUnits(balance, 18);
            setAgcBalance(formattedBalance);
        } catch (error) {
            console.error('Failed to fetch AGC balance:', error);
            setAgcBalance('N/A'); // Show fallback value if fetching fails
        }
    }, [account]);
    

    // Fetch balance whenever account changes
    useEffect(() => {
        if (account) {
            fetchAgcBalance();
        }
    }, [account, fetchAgcBalance]);

 

    // Analytics functionality
    const viewAnalytics = async (tokenAddress) => {
        try {
            const response = await fetch(`http://localhost:5000/analytics/${tokenAddress}`);
            const data = await response.json();

            setCurrentAnalytics(data); // Update state with fetched analytics
            setIsAnalyticsOpen(true); // Open the analytics modal
        } catch (error) {
            console.error('Error fetching analytics:', error);
            alert('Failed to fetch analytics. Please try again later.');
        }
    };
    
    
    const transferToken = (tokenAddress) => {
        setCurrentToken(tokenAddress); // Set the current token for the modal
        setIsModalOpen(true); // Open the modal
    };

    const handleTransfer = async (tokenAddress, recipient, amount) => {
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, signer);

            const tokenDecimals = tokenDetails.find(
                (token) => token.address === tokenAddress
            )?.decimals || 18; // Default to 18 decimals if not found

            const tx = await tokenContract.transfer(
                recipient,
                ethers.parseUnits(amount, tokenDecimals)
            );
            await tx.wait();

            alert('Transfer successful!');
            setIsModalOpen(false); // Close modal on success
        } catch (error) {
            console.error('Transfer failed:', error);
            alert('Transfer failed. Check console for details.');
        }
    };
    

    return (
        <div className="container">
            {/* Live Token Price Tracker at the top center */}
            <div className="price-section top-center">
                <TokenPriceTracker />
            </div>
            <h1 className="main-header">My Tokens</h1>
            <p className="small-text">Made by PrestigeNode</p>
            <div className="wallet-info">
                {!account ? (
                    <button className="connect-wallet1" onClick={connectWallet}>
                        <img src={WalletIcon} alt="Wallet Icon" className="wallet-icon" />
                        Connect Wallet
                    </button>
                ) : (
                    <div className="connected-wallet">
                        <img src={WalletIcon} alt="Wallet Icon" className="wallet-icon" />
                        <span className="wallet-address" title={account}>
                            {`${account.slice(0, 6)}...${account.slice(-4)}`}
                        </span>
                        <span className="wallet-balance">
                            {agcBalance ? `${parseFloat(agcBalance).toFixed(2)} AGC` : "Loading..."}
                        </span>
                    </div>
                )}
            </div>
            {loadingTokens ? (
                <p className="my-tokens-page-loading-message">Finding Tokens...</p>
            ) : tokenDetails.length === 0 ? (
                <p className="my-tokens-page-no-tokens-message">
                    No tokens found.{" "}
                    <Link to="/" className="create-token-link">
                        Create your first token!
                    </Link>
                </p>
            ) : (
                <>
                    <div className="filters-toggle-container">
                        <button
                            className="filters-toggle-button"
                            onClick={() => setFiltersVisible(!filtersVisible)}
                        >
                            Filters {filtersVisible ? "▼" : "▲"}
                        </button>
                    </div>
                    {filtersVisible && (
                        <div className="my-tokens-page-filters">
                            <div className="decimals-and-sort">
                                <div className="filter-group">
                                    <label htmlFor="sort-select" className="filter-label">
                                        Sort by:
                                    </label>
                                    <select
                                        id="sort-select"
                                        className="filter-select"
                                        value={sortOption}
                                        onChange={(e) => setSortOption(e.target.value)}
                                    >
                                        <option value="name">Name</option>
                                        <option value="balance">Balance</option>
                                        <option value="supply">Supply</option>
                                    </select>
                                </div>
                                <div className="filter-group">
                                    <label htmlFor="decimals-select" className="filter-label">
                                        Decimals:
                                    </label>
                                    <select
                                        id="decimals-select"
                                        className="filter-select"
                                        value={filterByDecimals}
                                        onChange={(e) => setFilterByDecimals(e.target.value)}
                                    >
                                        <option value="all">All</option>
                                        {[...Array(18)].map((_, i) => (
                                            <option key={i} value={18 - i}>
                                                {18 - i}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id="revoked-mint-checkbox"
                                    className="filter-checkbox"
                                    checked={showRevokedMint}
                                    onChange={(e) => setShowRevokedMint(e.target.checked)}
                                />
                                <label htmlFor="revoked-mint-checkbox" className="checkbox-label">
                                    Revoked Mint
                                </label>
                            </div>
                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id="revoked-pausable-checkbox"
                                    className="filter-checkbox"
                                    checked={showRevokedPausable}
                                    onChange={(e) => setShowRevokedPausable(e.target.checked)}
                                />
                                <label htmlFor="revoked-pausable-checkbox" className="checkbox-label">
                                    Revoked Pausable
                                </label>
                            </div>
                            <div className="balance-filters">
                                <div className="filter-group">
                                    <label htmlFor="min-balance-input" className="filter-label">
                                        Min Balance:
                                    </label>
                                    <input
                                        type="number"
                                        id="min-balance-input"
                                        className="filter-input"
                                        value={minBalance}
                                        onChange={(e) => setMinBalance(e.target.value)}
                                    />
                                </div>
                                <div className="filter-group">
                                    <label htmlFor="max-balance-input" className="filter-label">
                                        Max Balance:
                                    </label>
                                    <input
                                        type="number"
                                        id="max-balance-input"
                                        className="filter-input"
                                        value={maxBalance}
                                        onChange={(e) => setMaxBalance(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <ul className="my-tokens-page-token-list">
                        {sortedAndFilteredTokens.map((token, index) => {
                            const formattedSupply = new Intl.NumberFormat().format(token.totalSupply);
                            const formattedBalance = new Intl.NumberFormat().format(token.balance);
                            const balancePercentage = parseFloat(
                                (token.balance / token.totalSupply) * 100
                            ).toFixed(2);
    
                            return (
                                <li key={index} className="my-tokens-page-token-card">
                                    <div className="token-card-header">
                                        <h2 className="token-name">
                                            {token.name} ({token.symbol})
                                        </h2>
                                    </div>
    
                                    <div className="my-token-details">
                                        <div className="my-token-details-row">
                                            <strong>Total Supply:</strong>
                                            <span>{formattedSupply}</span>
                                        </div>
                                        <div className="my-token-details-row">
                                            <strong>Your Balance:</strong>
                                            <span>
                                                {formattedBalance}{" "}
                                                ({balancePercentage.endsWith(".00")
                                                    ? parseInt(balancePercentage, 10)
                                                    : balancePercentage}
                                                %)
                                            </span>
                                        </div>
                                        <div className="my-token-details-row">
                                            <strong>Decimals:</strong>
                                            <span>{token.decimals}</span>
                                        </div>
                                        <div className="my-token-details-row">
                                            <strong>Contract Address:</strong>
                                            <a
                                                href={`https://scanner.argoscan.net/token/${token.address}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="token-contract-link"
                                            >
                                                {token.address}
                                            </a>
                                        </div>
                                        <div className="my-token-details-row">
                                            <strong>Minting Revoked:</strong>
                                            <span
                                                className={`status ${
                                                    token.mintingRevoked ? "green" : "red"
                                                }`}
                                            >
                                                {token.mintingRevoked ? "Yes" : "No"}
                                            </span>
                                        </div>
                                        <div className="my-token-details-row">
                                            <strong>Pausability Revoked:</strong>
                                            <span
                                                className={`status ${
                                                    token.pausabilityRevoked ? "green" : "red"
                                                }`}
                                            >
                                                {token.pausabilityRevoked ? "Yes" : "No"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="token-card-footer">
                                        <button
                                            className="token-action-button"
                                            onClick={() => viewAnalytics(token.address)}
                                        >
                                            Analytics
                                        </button>
                                        <button
                                            className="token-action-button"
                                            onClick={() => transferToken(token.address)}
                                        >
                                            Transfer
                                        </button>
                                        <button
                                            className="token-action-button"
                                            onClick={() => navigate(`/mint?token=${token.address}`)}
                                        >
                                            Mint
                                        </button>
                                        <button
                                            className="token-action-button"
                                            onClick={() => navigate(`/burn?token=${token.address}`)}
                                        >
                                            Burn
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
{isAnalyticsOpen && (
    <AnalyticsModal
        isOpen={isAnalyticsOpen}
        onClose={() => setIsAnalyticsOpen(false)}
        analyticsData={currentAnalytics}
    />
)}
{isModalOpen && currentToken && (
    <TransferModal
        tokens={tokenDetails}
        tokenAddress={currentToken}
        onTransfer={handleTransfer}
        onClose={() => setIsModalOpen(false)}
    />
)}

                </>
            )}
        </div>
    );    
}      

export default MyTokensPage;
