import React, { useState, useEffect, useCallback } from 'react';
import TokenPriceTracker from './TokenPriceTracker';
import WalletIcon from './assets/wallet-icon.svg';
const ethers = require("ethers");

const BurnTokenPage = ({ account, connectWallet, agcBalance }) => {
    const [contract, setContract] = useState(null);
    const [userTokens, setUserTokens] = useState([]);
    const [selectedToken, setSelectedToken] = useState('');
    const [burnAmount, setBurnAmount] = useState('');
    const [burnTokenError, setBurnTokenError] = useState('');
    const [loadingTokens, setLoadingTokens] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const TokenFactoryArtifact = require('./artifacts/contracts/TokenFactory.sol/TokenFactory.json'); // Adjust the path as needed
    const ERC20_ABI = [
        "function burn(uint256 amount) public",
        "function decimals() view returns (uint8)",
        "function name() view returns (string)",
        "function symbol() view returns (string)"
    ];

    // Initialize the TokenFactory contract
    useEffect(() => {
        if (!account) return;

        const initializeContract = async () => {
            const tokenFactoryAddress = '0xD147473E4952E75878b967686feedc24137A084C'; // Replace with your TokenFactory address
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const tokenFactoryContract = new ethers.Contract(tokenFactoryAddress, TokenFactoryArtifact.abi, signer);

            setContract(tokenFactoryContract);
        };

        initializeContract();
    }, [account]);

    // Fetch user tokens
    const fetchUserTokens = useCallback(async () => {
        if (!contract || !account) return;

        try {
            setLoadingTokens(true);

            const tokens = await contract.getUserTokens(account);

            // Fetch additional details like name and symbol
            const enrichedTokens = await Promise.all(
                tokens.map(async (address) => {
                    const provider = new ethers.BrowserProvider(window.ethereum);
                    const tokenContract = new ethers.Contract(address, ERC20_ABI, provider);

                    const name = await tokenContract.name();
                    const symbol = await tokenContract.symbol();

                    return { address, name, symbol };
                })
            );

            setUserTokens(enrichedTokens);
            setSelectedToken(enrichedTokens.length > 0 ? enrichedTokens[0].address : '');
            setLoadingTokens(false);
        } catch (error) {
            console.error("Error fetching user tokens:", error);
            setBurnTokenError("Failed to load user tokens.");
            setLoadingTokens(false);
        }
    }, [contract, account]);

    // Fetch tokens when the contract is ready
    useEffect(() => {
        if (contract) fetchUserTokens();
    }, [contract, fetchUserTokens]);

    // Burn tokens
    const handleBurn = async () => {
        setBurnTokenError(""); // Reset error message initially
    
        if (!selectedToken) {
            setBurnTokenError("Please select a token to burn.");
            return;
        }
    
        if (!burnAmount || isNaN(burnAmount) || parseFloat(burnAmount) <= 0) {
            setBurnTokenError("Please enter a valid amount to burn.");
            return;
        }
    
        try {
            setIsLoading(true); // Show loading spinner
    
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
    
            // Connect to the selected token's contract
            const tokenContract = new ethers.Contract(
                selectedToken,
                [
                    "function burn(uint256 amount) public",
                    "function decimals() view returns (uint8)"
                ],
                signer
            );
    
            // Retrieve token decimals
            const actualDecimals = await tokenContract.decimals();
    
            // Parse the burnAmount correctly based on actualDecimals
            const amount = ethers.parseUnits(burnAmount, actualDecimals);
    
            // Burn the tokens
            const tx = await tokenContract.burn(amount);
            await tx.wait();
    
            // Display transaction hash with a clickable link
            setBurnTokenError(
                <div className="transaction-message">
                    <p>Burned {burnAmount} tokens.</p>
                    <p>
                        Transaction ID:{" "}
                        <a
                            href={`https://scanner.argoscan.net/tx/${tx.hash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {tx.hash}
                        </a>
                    </p>
                </div>
            );            
            setBurnAmount("");
        } catch (error) {
            console.error("Error burning tokens:", error);
            if (error.code === "UNSUPPORTED_OPERATION") {
                setBurnTokenError("Contract runner does not support calling. Check your signer.");
            } else {
                setBurnTokenError("Failed to burn tokens. Please try again.");
            }
        } finally {
            setIsLoading(false); // Hide loading spinner
        }
    };
    
    return (
        <div className="burn-container">
            {/* Live Token Price Tracker */}
            <div className="price-section top-center">
                <TokenPriceTracker />
            </div>
    
            {/* Wallet Info */}
            <h1 className="main-header">Burn Tokens</h1>
            <p className="small-text">Made by PrestigeNode</p>
            <div className="wallet-info">
                {!account ? (
                    <button className="connect-wallet1" onClick={connectWallet}>
                        <img src={WalletIcon} alt="Wallet Icon" className="wallet-icon" />
                        Connect Wallet
                    </button>
                ) : (
                    <div className="connected-wallet">
                        <img src={WalletIcon} alt="Wallet Icon" className="wallet-icon" />
                        <span className="wallet-address" title={account}>
                            {`${account.slice(0, 6)}...${account.slice(-4)}`}
                        </span>
                        <span className="wallet-balance">
                            {agcBalance ? `${parseFloat(agcBalance).toFixed(2)} AGC` : "Loading..."}
                        </span>
                    </div>
                )}
            </div>
    
            {/* Burn Token Form */}
            <div className="token-form">
                <h2 className="form-header">Burn Tokens</h2>
                <div className="form-group">
                    <label className="form-label">Select Token</label>
                    <select
                        value={selectedToken}
                        onChange={(e) => setSelectedToken(e.target.value)}
                        className="token-select"
                    >
                        {loadingTokens ? (
                            <option>Loading tokens...</option>
                        ) : userTokens.length === 0 ? (
                            <option value="">No tokens available</option>
                        ) : (
                            userTokens.map((token, index) => (
                                <option key={index} value={token.address}>
                                    {token.name} ({token.symbol}) - {`${token.address.slice(0, 6)}...${token.address.slice(-4)}`}
                                </option>
                            ))
                        )}
                    </select>
                </div>
                <div className="form-group">
                    <label className="form-label">Amount to Burn</label>
                    <input
                        type="text"
                        inputMode="decimal"
                        pattern="^\d*\.?\d*$"
                        placeholder="Enter amount to burn"
                        value={burnAmount}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                                setBurnAmount(value);
                            }
                        }}
                    />
                </div>
                <button onClick={handleBurn} className="burn-button">
                    Burn Tokens
                </button>
                {burnTokenError && <p style={{ color: 'red', marginTop: '5px' }}>{burnTokenError}</p>}
            </div>

            {isLoading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                    <p>Burning tokens... Please wait.</p>
                </div>
            )}
        </div>
    );    
};

export default BurnTokenPage;
